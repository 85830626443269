angular.module('ui.bootstrap.dropdown', ['ui.bootstrap.position'])

    .constant('dropdownConfig', {
        openClass: 'open'
    })

    .service('dropdownService', ['$document', '$rootScope', function ($document, $rootScope) {
        var openScope = null;

        this.open = function (dropdownScope) {
            if (!openScope) {
                $document.bind('click', closeDropdown);
                $document.bind('keydown', keybindFilter);
            }

            if (openScope && openScope !== dropdownScope) {
                openScope.isOpen = false;
            }

            openScope = dropdownScope;
        };

        this.close = function (dropdownScope) {
            if (openScope === dropdownScope) {
                openScope = null;
                $document.unbind('click', closeDropdown);
                $document.unbind('keydown', keybindFilter);
            }
        };

        var closeDropdown = function (evt) {
            if (
                evt &&
                evt.type === 'click' &&
                !evt.target.classList.contains('search-term') &&
                !evt.target.classList.contains('fa-remove') &&
                openScope.$parent &&
                openScope.$parent.onSearchBlur
            ) {
                openScope.$parent.onSearchBlur(evt);
            }

            // This method may still be called during the same mouse event that
            // unbound this event handler. So check openScope before proceeding.
            if (!openScope) {
                return;
            }

            if (evt && openScope.getAutoClose() === 'disabled') {
                return;
            }

            var toggleElement = openScope.getToggleElement();
            if (evt && toggleElement && toggleElement[0].contains(evt.target)) {
                return;
            }

            var dropdownElement = openScope.getDropdownElement();
            if (evt && openScope.getAutoClose() === 'outsideClick' &&
                dropdownElement && dropdownElement[0].contains(evt.target)) {
                return;
            }

            openScope.isOpen = false;

            if (!$rootScope.$$phase) {
                openScope.$apply();
            }
        };

        var keybindFilter = function (evt) {
            if (evt.which === 27) {
                openScope.focusToggleElement();
                closeDropdown();
            } else if (openScope.isKeynavEnabled() && /(38|40)/.test(evt.which) && openScope.isOpen) {
                evt.preventDefault();
                evt.stopPropagation();
                openScope.focusDropdownEntry(evt.which);
            }
        };
    }])

    .controller('DropdownController', ['$scope', '$element', '$attrs', '$parse', 'dropdownConfig', 'dropdownService', '$animate', '$position', '$document', '$compile', '$templateRequest', function ($scope, $element, $attrs, $parse, dropdownConfig, dropdownService, $animate, $position, $document, $compile, $templateRequest) {
        var self = this,
            scope = $scope.$new(), // create a child scope so we are not polluting original one
            templateScope,
            openClass = dropdownConfig.openClass,
            getIsOpen,
            setIsOpen = angular.noop,
            toggleInvoker = $attrs.onToggle ? $parse($attrs.onToggle) : angular.noop,
            appendToBody = false,
            appendTo = null,
            keynavEnabled = false,
            fluidMenu = false,
            selectedOption = null,
            positionInterval = undefined,
            body = $document.find('body');

        $element.addClass('dropdown');

        this.init = function () {

            if ($attrs.isOpen) {
                getIsOpen = $parse($attrs.isOpen);
                setIsOpen = getIsOpen.assign;

                $scope.$watch(getIsOpen, function (value) {
                    scope.isOpen = !!value;
                });
            }

            if (angular.isDefined($attrs.dropdownAppendTo)) {
                var appendToEl = $parse($attrs.dropdownAppendTo)(scope);
                if (appendToEl) {
                    appendTo = angular.element(appendToEl);
                }
            }

            appendToBody = angular.isDefined($attrs.dropdownAppendToBody);
            keynavEnabled = angular.isDefined($attrs.uibKeyboardNav);
            fluidMenu = angular.isDefined($attrs.fluidMenu);

            if (appendToBody && !appendTo) {
                appendTo = body;
            }

            if (appendTo && self.dropdownMenu) {
                appendTo.append(self.dropdownMenu);
                $element.on('$destroy', function handleDestroyEvent() {
                    self.dropdownMenu.remove();
                });
            }
        };

        this.toggle = function (open) {
            return scope.isOpen = arguments.length ? !!open : !scope.isOpen;
        };

        // Allow other directives to watch status
        this.isOpen = function () {
            return scope.isOpen;
        };

        var positionDropdownMenu = function (isOpen) {
            //var pos = $position.positionElements($element, self.dropdownMenu, 'bottom-left', true);
            var dropUp = $element.hasClass('dropup');
            var rightalign = self.dropdownMenu.hasClass('dropdown-menu-right');
            var placement = 'bottom-left';
            if(dropUp && rightalign){
                placement = 'top-right';
            } else if (dropUp) {
                placement = 'top-left';
            } else if (rightalign) {
                placement = 'bottom-right';
            }

            var pos = $position.positionElements($element, self.dropdownMenu, placement, true);

            var css = {
                top: pos.top + 'px',
                left: pos.left + 'px',
                //right: 'auto',
                display: isOpen ? 'block' : 'none',
                minWidth: 0
            };

            if (isOpen) {
                self.dropdownMenu.addClass('is-open');
            } else {
                self.dropdownMenu.removeClass('is-open');
            }

            if (fluidMenu) {
                css.minWidth = $element.prop('offsetWidth');
            }

            // Need to adjust our positioning to be relative to the appendTo container
            // if it's not the body element
            if (!appendToBody) {
                var appendOffset = $position.offset(appendTo);

                css.top = pos.top - appendOffset.top + 'px';

                if (!rightAlign) {
                    css.left = pos.left - appendOffset.left + 'px';
                } else {
                    css.right = (window.innerWidth - (pos.left - appendOffset.left + $element.prop('offsetWidth'))) + 'px';
                }
            }

            self.dropdownMenu.css(css);
        };

        scope.getToggleElement = function () {
            return self.toggleElement;
        };

        scope.getAutoClose = function () {
            return $attrs.autoClose || 'always'; //or 'outsideClick' or 'disabled'
        };

        scope.getElement = function () {
            return $element;
        };

        scope.isKeynavEnabled = function () {
            return keynavEnabled;
        };

        scope.focusDropdownEntry = function (keyCode) {
            var elems = self.dropdownMenu ? //If append to body is used.
                (angular.element(self.dropdownMenu).find('a')) :
                ($element.find('ul').eq(0).find('a'));

            switch (keyCode) {
                case (40): {
                    if (!angular.isNumber(self.selectedOption)) {
                        self.selectedOption = 0;
                    } else {
                        self.selectedOption = (self.selectedOption === elems.length - 1 ?
                            self.selectedOption :
                            self.selectedOption + 1);
                    }
                    break;
                }
                case (38): {
                    if (!angular.isNumber(self.selectedOption)) {
                        self.selectedOption = elems.length - 1;
                    } else {
                        self.selectedOption = self.selectedOption === 0 ?
                            0 : self.selectedOption - 1;
                    }
                    break;
                }
            }

            if (elems[self.selectedOption]) {
                elems[self.selectedOption].focus();
            }
        };

        scope.getDropdownElement = function () {
            return self.dropdownMenu;
        };

        scope.focusToggleElement = function () {
            if (self.toggleElement) {
                self.toggleElement[0].focus();
            }
        };

        scope.$watch('isOpen', function (isOpen, wasOpen) {
            if (appendTo && self.dropdownMenu) {
                positionDropdownMenu(isOpen);
            }

            var openContainer = appendTo ? appendTo : $element;

            $animate[isOpen ? 'addClass' : 'removeClass'](openContainer, openClass).then(function () {
                if (angular.isDefined(isOpen) && isOpen !== wasOpen) {
                    toggleInvoker($scope, {open: !!isOpen});
                }
            });

            if (isOpen) {
                if (self.dropdownMenuTemplateUrl) {
                    $templateRequest(self.dropdownMenuTemplateUrl).then(function (tplContent) {
                        templateScope = scope.$new();
                        $compile(tplContent.trim())(templateScope, function (dropdownElement) {
                            var newEl = dropdownElement;
                            self.dropdownMenu.replaceWith(newEl);
                            self.dropdownMenu = newEl;
                        });
                    });
                }

                scope.focusToggleElement();
                dropdownService.open(scope);

                if (appendToBody) {
                    positionDropdownMenu(true);
                    positionInterval = setInterval(function () {
                        positionDropdownMenu(true);
                    }, 100);
                }
            } else {
                if (self.dropdownMenuTemplateUrl) {
                    if (templateScope) {
                        templateScope.$destroy();
                    }
                    var newEl = angular.element('<ul class="dropdown-menu"></ul>');
                    self.dropdownMenu.replaceWith(newEl);
                    self.dropdownMenu = newEl;
                }

                dropdownService.close(scope);
                self.selectedOption = null;

                clearInterval(positionInterval);
            }

            if (angular.isFunction(setIsOpen)) {
                setIsOpen($scope, isOpen);
            }
        });

        $scope.$on('$locationChangeSuccess', function () {
            if (scope.getAutoClose() !== 'disabled') {
                scope.isOpen = false;
            }
        });

        var offDestroy = $scope.$on('$destroy', function () {
            scope.$destroy();
        });
        scope.$on('$destroy', offDestroy);
    }])

    .directive('dropdown', function () {
        return {
            controller: 'DropdownController',
            link: function (scope, element, attrs, dropdownCtrl) {
                dropdownCtrl.init();
            }
        };
    })

    .directive('dropdownMenu', function () {
        return {
            restrict: 'AC',
            require: '?^dropdown',
            link: function (scope, element, attrs, dropdownCtrl) {
                if (!dropdownCtrl || angular.isDefined(attrs.dropdownNested)) {
                    return;
                }

                element.addClass('dropdown-menu');

                var tplUrl = attrs.templateUrl;
                if (tplUrl) {
                    dropdownCtrl.dropdownMenuTemplateUrl = tplUrl;
                }

                if (!dropdownCtrl.dropdownMenu) {
                    dropdownCtrl.dropdownMenu = element;
                }
            }
        };
    })

    .directive('dropdownToggle', function () {
        return {
            require: '?^dropdown',
            link: function (scope, element, attrs, dropdownCtrl) {
                if (!dropdownCtrl) {
                    return;
                }

                element.addClass('dropdown-toggle');

                dropdownCtrl.toggleElement = element;

                var toggleDropdown = function (event) {
                    event.preventDefault();

                    if (!element.hasClass('disabled') && !attrs.disabled) {
                        scope.$apply(function () {
                            dropdownCtrl.toggle();
                        });
                    }
                };

                element.bind('click', toggleDropdown);

                // WAI-ARIA
                element.attr({'aria-haspopup': true, 'aria-expanded': false});
                scope.$watch(dropdownCtrl.isOpen, function (isOpen) {
                    element.attr('aria-expanded', !!isOpen);
                });

                scope.$on('$destroy', function () {
                    element.unbind('click', toggleDropdown);
                });
            }
        };
    });
