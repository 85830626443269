/**
 * Items for sorting MUST have the same position order as their indexes in the array!!!
 */

angular.module('naex')

    .directive('nxSortable', function ($timeout) {
        return {
            templateUrl: 'common/_directive/nxSortable/nxSortable.html',
            transclude: true,
            replace: true,
            scope: {
                modelList: '=nxSortable',
                inlineSortable: '=',
                onSort: '&',
                onReceive: '&',
                orderBy: '@',
                sortableOptions: '='
            },
            link: function (scope, element, attrs) {

                var orderByProperty = scope.orderBy ? scope.orderBy : 'pos';
                $timeout(function(){
                    if (!scope.modelList) {
                        return;
                    }

                    scope.modelList.sort(function(a, b) { return a[orderByProperty] - b[orderByProperty]});
                });

                scope.sortableOptionsExtended = {
                    forcePlaceholderSize: true,
                    tolerance: "pointer",
                    distance: 5
                };

                _.extend(scope.sortableOptionsExtended, scope.sortableOptions);

                //ui-sortable calls scope.apply implicit on every event
                scope.sortableOptionsExtended.stop = function (event, ui) {
                    if (scope.cancelStop){
                        scope.cancelStop = false;
                        return;
                    }

                    var orderedIds = [];
                    $timeout(function () {
                        angular.forEach(scope.modelList, function (model, index) {
                            model[orderByProperty] = index + 1;
                            orderedIds.push(model.id);
                        });

                        scope.onSort({
                            $event: event,
                            $ui: ui,
                            $orderedIds: orderedIds
                        });
                    });
                };

                //ui-sortable calls scope.apply implicit on every event
                scope.sortableOptionsExtended.receive = function (event, ui) {
                    var orderedIds = [];

                    var senderScope = angular.element(ui.sender).isolateScope();
                    senderScope.cancelStop = true;

                    $timeout(function () {
                        angular.forEach(scope.modelList, function (model, index) {
                            model[orderByProperty] = index + 1;
                            orderedIds.push(model.id);
                        });

                        scope.onReceive({
                            $event: event,
                            $ui: ui,
                            $orderedIds: orderedIds
                        });
                    });
                };
            }
        }
    });
