angular.module('naex.project')

    .controller('ProjectCommentListCtrl', function ($scope, $stateParams, authService, projectTabBadgeService) {

        $scope.$watchCollection('projectCommentList', function() {
            projectTabBadgeService.setTabBadges();
        });

        $scope.canCreate = function() {
            if ($stateParams.taskId === '') {
                return false;
            }

            if (authService.hasPermission('ProjectAdmin')) {
                return true;
            }

            return $stateParams.taskId !== '' && $stateParams.stepId === '';
        };

        $scope.updateTreeBadges();

    });