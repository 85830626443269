angular.module('naex.crossReference')

    .controller('CrossReferenceProjectEditSheetListCtrl', function ($scope, messageBoxService, overviewService, CrossReferenceSheet) {

        $scope.sheetSettings = {
            type: null
        };

        $scope.onSheetChange = function(sheet, property) {
            sheet.$save([property], null, {
                jsonifyObjects: true
            });
        };

        $scope.addSheet = function() {
            if (!$scope.sheetSettings.type) {
                return;
            }

            var sheet = new CrossReferenceSheet();
            sheet.project = $scope.project;
            sheet.position = $scope.sheetList.length;
            sheet.type = $scope.sheetSettings.type;
            sheet.name = overviewService.overviewTypeList[sheet.type].name;

            sheet.$save().then(function() {
                $scope.sheetList.push(sheet);

                $scope.sheetSettings.type = null;
                updateOverviewList();
            });
        };

        $scope.deleteSheet = function(sheet) {
            messageBoxService.confirmWarning('Delete', 'Do you really want to delete this sheet?', 'Yes', function() {
                sheet.$remove($scope.sheetList);
                updateOverviewList();
            });
        };

        function updateOverviewList() {
            $scope.typeList = [];
            angular.forEach(overviewService.overviewTypeList, function(item, key) {
                if (!_.find($scope.sheetList, function(sheet) {
                    return sheet.type === key;
                }) || key === 'simple-color') {
                    $scope.typeList.push({
                        id: key,
                        name: item.name
                    });
                }
            });

            $scope.typeList = _.sortBy($scope.typeList, 'name');
            $scope.typeList.unshift({
                id: null,
                name: 'Please choose...'
            });
        }

        updateOverviewList();

    });
