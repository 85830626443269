angular.module('naex')

    .factory('pageTitleService', function ($rootScope, translationService) {
        var defaultTitle = 'Naexas';

        $rootScope.pageTitle = defaultTitle;

        return {
            setTitle: function (title, subtitle) {
                var finalTitle = defaultTitle;

                if (title) {
                    finalTitle = translationService.translate(title, 'company');
                }

                if (subtitle) {
                    finalTitle = finalTitle + ' | ' + translationService.translate(subtitle, 'company');
                }

                if (finalTitle !== defaultTitle) {
                    finalTitle = finalTitle + ' | ' + defaultTitle;
                }

                $rootScope.pageTitle = finalTitle;
            }
        };
    });