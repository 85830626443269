angular.module('naex')

    .factory('webpackImageCache', function () {

        var cache = {};

        function importAll(r) {
            r.keys().forEach((key) => (cache[key] = r(key)));
        }

        importAll(require.context('/src/assets/images', true, /\.png|\.jpg$/));

        return {
            getPath: function (file) {
                return cache['./' + file];
            }
        };
    });
