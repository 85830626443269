angular.module('naex.crossReference')

    .controller('CrossReferenceProjectCtrl', function ($scope, $modal, $state, $stateParams, $timeout, $window,
                                                       autoCompleteFilterService, modelCache, overviewService, worldMap,
                                                       CrossReferenceSheet, CrossReferenceItem, CrossReferenceProject) {

        $scope.filterService = autoCompleteFilterService;
        $scope.isLoading = false;
        $scope.sheetList = [];
        $scope.currentSheet = null;
        $scope.overviewType = null;

        $scope.selectSheet = function(sheet) {
            $scope.isLoading = true;
            $scope.currentSheet = sheet;
            $scope.overviewType = overviewService.overviewTypeList[sheet.type];
            $scope.setMapTitle(sheet.name);
            $state.go('crossReference.sheet', {
                projectId: $scope.currentProject.id,
                sheetId: $scope.currentSheet.id
            }, {notify: false});

            $scope.initWorldMap().then(function() {
                angular.forEach($scope.currentProject.territoryList, function (territory) {
                    if (![
                        'brand-strategy-overview',
                        'simple-color'
                    ].includes($scope.currentSheet.type)) {
                        $scope.setColor(territory, $scope.overviewType.valueProperty, true);
                        return;
                    }

                    var color = 'gray';
                    if (territory.valueCustom && territory.valueCustom[$scope.currentSheet.id]) {
                        color = territory.valueCustom[$scope.currentSheet.id];
                    }

                    $scope.setColor(territory, null, true, color);
                });

                if ($scope.currentSheet.type !== 'competitor-activity') {
                    $scope.setWatchingLink(null);
                }

                $scope.isLoading = false;
            });
        };

        function initSheetList() {
            $scope.isLoading = true;
            CrossReferenceSheet.getAll({ projectId: $scope.currentProject.id }).then(function(sheetList) {
                $scope.sheetList = sheetList;
                if ($scope.sheetList.length > 0) {
                    if ($stateParams.sheetId) {
                        var sheet = _.find($scope.sheetList, function(sheet) {
                            return sheet.id === $stateParams.sheetId;
                        });
                        if (sheet) {
                            $scope.selectSheet(sheet);
                        } else {
                            $scope.isLoading = false;
                        }
                    } else {
                        $scope.selectSheet($scope.sheetList[0]);
                    }
                } else {
                    $scope.isLoading = false;
                }
            });
        }

        initSheetList();

        $scope.setColor = function(item, valueProperty, skipAnimation, color) {
            if (!skipAnimation) {
                skipAnimation = false;
            }

            color = color || item[valueProperty];
            switch (color) {
                case 'gray':
                    color = '#c3c3c3';
                    break;
                case 'green':
                    color = '#3aad3a';
                    break;
                case 'yellow':
                    color = '#e8e82e';
                    break;
                case 'orange':
                    color = '#df9e0d';
                    break;
                case 'red':
                    color = '#ad3a3a';
                    break;
                case 'blue':
                    color = '#3a87ad';
                    break;
                case 'purple':
                    color = '#753aad';
            }

            worldMap.colorCountry(item.country.iso3, color, skipAnimation);
        };

        $scope.initWorldMap = function() {
            return $timeout(function() {
                return $timeout(function() {
                    worldMap.init('cross-reference-map', {
                        countryInfo: {
                            handler: function(props) {
                                if (props) {
                                    var iso3 = worldMap.getCountryParent(props.adm0_iso),
                                        evaluation = overviewService.getEvaluation($scope.currentSheet, iso3, $scope.currentProject.territoryList);

                                    $scope.hoverTerritory(props.adm0_iso, evaluation);
                                } else {
                                    $scope.hoverReset();
                                }
                            }
                        }
                    });
                });
            });
        };

        $scope.editProject = function (project) {
            var modal = $modal.open({
                windowClass: 'modal-width-1600 modal-with-tabs',
                controller: ['$scope', 'project', 'sheetList', function ($modalScope, project, sheetList) {
                    $modalScope.project = project;
                    $modalScope.sheetList = sheetList;
                }],
                resolve: {
                    project: function() {
                        return CrossReferenceProject.get(project.id);
                    },
                    sheetList: function() {
                        return CrossReferenceSheet.getAll({ projectId: project.id });
                    }
                },
                templateUrl: 'crossReference/projectEditPopup/projectEditPopup.html'
            });

            modal.result.then(function() {
                $scope.selectProject(project.id, true);
            });
        };

        $scope.openWatching = function(item) {
            if (!$scope.currentSheet || !$scope.currentProject.watchingGroup) {
                return;
            }

            var parameters = {
                watchingGroup: {
                    id: $scope.currentProject.watchingGroup.id,
                    name: $scope.currentProject.watchingGroup.name
                }
            };

            if (item) {
                parameters.countryCode = item.country.iso2;
            }

            if ($scope.currentSheet.type === 'competitor-activity') {
                parameters.reportDateFrom = moment().subtract(overviewService.getSliderValue(), 'months').format('YYYYMMDD');
            }

            var link = $state.href('watching.watchingHitList', { filters: JSON.stringify(parameters) }, { absolute: true });
            if (item) {
                $window.open(link, '_blank');
            } else {
                $scope.setWatchingLink(link);
            }
        };

    });
