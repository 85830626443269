/**
 * Remove make the root element ul instead of div
 */
angular.module('template/pagination/pagination.html')

    .run(function($templateCache) {
        $templateCache.put("template/pagination/pagination.html",
            "<ul class=\"pagination\">\n" +
                "  <li ng-repeat=\"page in pages\" ng-class=\"{active: page.active, disabled: page.disabled}\"><a ng-click=\"selectPage(page.number)\">{{page.text}}</a></li>\n" +
                "</ul>\n" +
                "");
    });