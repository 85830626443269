angular.module('naex.trademark')

    .factory('ViennaClassificationRecognition', function (Base) {

        var ViennaClassificationRecognition = Base.extend({
            name: 'viennaClassificationRecognition',
            subModels: {
                employee: {
                    model: 'Employee',
                },
                imageList: {
                    model: 'ViennaClassificationRecognition',
                },
            },
            customMethods: {
                getWithImage: { method: 'GET' },
                getHistory: { method: 'GET', model: true }
            }
        });

        return ViennaClassificationRecognition;
    });