angular.module('naex.watching')

    .controller('WatchingHitOrderSelectorCtrl', function ($scope, $rootScope, $state, authService, modelSelector,
                                                          translationService, Company, WatchingGroup) {

        // View
        $scope.isAdmin = authService.hasPermission('WatchingAdmin');
        $scope.currentView = null;
        $scope.filterProperty = 'watchingGroup_id';
        $scope.selectView = function(view, skipValueReset) {
            $scope.currentView = view;
            if (!skipValueReset) {
                $scope.kendoGrid.filters[$scope.filterProperty].value = [];
            }
            $scope.filterProperty = $scope.currentView === 'watchingGroup' ? 'watchingGroup_id' : 'client_id';

            $scope.search = {};
            $scope.allSelected = true;
            $scope.list = [];

            $scope.kendoGrid.applyFilters();
            if (!skipValueReset) {
                $scope.doSearch();
            }

            $scope.$broadcast('nxScrollbar:scrollToPos', 0);
        };

        //View items
        var debounceFilters = _.debounce($scope.kendoGrid.applyFilters, 350);

        $scope.search = {};
        $scope.allSelected = true;
        $scope.list = [];

        $scope.resetSearch = function() {
            $scope.search.value = null;
            $scope.doSearch();
        };

        $scope.doSearch = _.debounce(function() {
            if ($scope.currentView === 'watchingGroup') {
                searchWatchingGroup();
            } else {
                searchCompany();
            }
        }, 300);

        $scope.showAll = function() {
            $scope.allSelected = true;
            $scope.kendoGrid.filters[$scope.filterProperty].value = [];
            modelSelector.selectAll($scope.list, false);

            debounceFilters();
            $scope.resetSearch();
        };

        $scope.toggleOrderViewItem = function(item) {
            item.$selected = !item.$selected;
            if (item.$selected) {
                $scope.kendoGrid.filters[$scope.filterProperty].value.push(item.id);
            } else {
                _.pull($scope.kendoGrid.filters[$scope.filterProperty].value, item.id);
            }

            $scope.allSelected = $scope.kendoGrid.filters[$scope.filterProperty].value.length === 0;
            debounceFilters();
        };

        function searchWatchingGroup(watchingGroupId) {
            var queryParams = {
                fields: 'name',
                query: $scope.search.value || null,
                hasPublicationWatch: $state.current.name.indexOf('watching.publicationWatchingHitList') !== -1,
                limit: 50
            };

            if (authService.hasPermission('WatchingAdmin')) {
                queryParams.excludeCorsearchAsiaWatchTrial = true;
            }

            WatchingGroup.autosuggest(queryParams).then(function(data) {
                $scope.list = _.sortBy(data, function(item) {
                    return item.name.toLowerCase();
                });

                if (watchingGroupId > 0) {
                    var group = _.find($scope.list, function (watchingGroup) {
                        return watchingGroup.id === watchingGroupId;
                    });
                    if (group) {
                        group.$selected = true;
                        $scope.allSelected = false;
                    } else {
                        $scope.allSelected = true;
                        $scope.kendoGrid.filters[$scope.filterProperty].value = [];
                    }
                } else {
                    $scope.allSelected = true;
                    $scope.kendoGrid.filters[$scope.filterProperty].value = [];
                }
            });
        }

        function searchCompany() {
            Company.autosuggest({
                fields: 'name,watchingCount',
                query: $scope.search.value || null,
                limit: 50
            }).then(function(data) {
                $scope.list = _.sortBy(data, function(item) {
                    return item.name.toLowerCase();
                });

                $scope.allSelected = true;
                $scope.kendoGrid.filters[$scope.filterProperty].value = [];
            });
        }

        var queryFilters = angular.isDefined($state.params.filters) ? JSON.parse($state.params.filters) : null;
        if (queryFilters && queryFilters.watchingGroup) {
            $scope.selectView('watchingGroup', queryFilters && queryFilters.watchingGroup);
            $scope.search.value = queryFilters.watchingGroup.name;
            searchWatchingGroup(queryFilters.watchingGroup.id);
        } else {
            $scope.selectView('watchingGroup');
        }


        // Translation
        translateTexts();

        $rootScope.$on('$translateChangeSuccess', function(){
            translateTexts();
        });

        function translateTexts(){
            var moduleName = 'watching';
            $scope.searchPlaceHolder = translationService.translate('Search...', moduleName);
        }

    });
