
angular.module("naex")
    .run(function($templateCache) {

        // This function is used to load all the html templates in the $templateCache
        // It uses webpack's require.context to load all the html files in the src folder

        function requireAll(requireContext) {
            return requireContext.keys().map(function(val){
                return {
                    tpl: requireContext(val),
                    name: val.replace('./', '')
                }
            });
        }

        let modules = requireAll(require.context("../", true, /\.html$/));
        modules.map(function (val) {
            if (typeof val.tpl === 'object') {
                console.log(val.name);
                console.log(val.tpl);
            }
            $templateCache.put(val.name, val.tpl);
        })
    });
