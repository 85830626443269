angular.module('naex.watching')

    .controller('DeviceEvaluationPopupCtrl', function ($scope, modelCache, watchingClientService,
                                                       WatchingHit, WatchingHitDeviceEvaluation) {

        $scope.isLoading = false;
        $scope.count = 0;
        $scope.id = null;
        $scope.watchingHit = null;
        $scope.client = null;
        $scope.imageLeft = null;
        $scope.imageRight = null;

        $scope.refresh = function() {
            $scope.isLoading = true;

            WatchingHitDeviceEvaluation.count().then(function(data) {
                $scope.count = data.count;

                if ($scope.count === 0) {
                    $scope.isLoading = false;
                    return;
                }

                WatchingHitDeviceEvaluation.getRandom().then(function(data) {
                    $scope.id = data.id;

                    WatchingHit.get($scope.id).then(function(watchingHit) {
                        $scope.watchingHit = watchingHit;

                        $scope.client = watchingClientService.getClient(watchingHit.watching);
                        $scope.imageLeft = null;
                        $scope.imageRight = null;

                        if ($scope.watchingHit.watching.imageList[0]) {
                            $scope.imageLeft = $scope.watchingHit.watching.imageList[0].$downloadUrl({size: '350x350'});
                        }

                        if ($scope.watchingHit.trademark.imageList[0]) {
                            $scope.imageRight = $scope.watchingHit.trademark.imageList[0].$downloadUrl({size: '350x350'});
                        }

                        $scope.isLoading = false;
                    });
                });
            });
        };

        $scope.setAlert = function(alertLevel) {
            var risk = null;
            switch (alertLevel) {
                case 'high':
                    risk = modelCache.get('Risk', 1);
                    break;
                case 'medium':
                    risk = modelCache.get('Risk', 2);
                    break;
                case 'low':
                    risk = modelCache.get('Risk', 3);
                    break;
            }

            $scope.watchingHit.ready = true;
            $scope.watchingHit.userRiskSimilarity = risk;

            WatchingHit.setSimilarityRiskByIds({}, {ids: [$scope.id], riskId: risk.id, similarityQuadrant: null}).then(function(){
                WatchingHit.setReadyByIds({}, {ids: [$scope.id], value: true}).then(function() {
                    $scope.refresh();
                });
            });

        };

        $scope.setClientTrademarkAndSkip = function(value, watchingHit) {
            $scope.setClientTrademark(value, watchingHit);
            $scope.refresh();
        };

        $scope.refresh();

    });
