angular.module('naex.crossReference')

    .controller('CrossReferenceHomeCtrl', function ($scope, $state, $timeout, modelCache, overviewService, pageTitleService,
                                                    worldMap, CrossReferenceProject) {

        $scope.overviewService = overviewService;
        $scope.splitterOptions = {
            panes: [
                {collapsible: false, scrollable: false, resizable: false, size: 500},
                {collapsible: false, scrollable: false, resizable: false}
            ]
        };

        $scope.currentProject = null;
        $scope.selectProject = function (projectId, reset) {
            angular.forEach($scope.countryCache, function(cacheItem) {
                cacheItem.color = overviewService.colorMap.gray;
                cacheItem.evaluation = null;
            });

            if (!projectId) {
                pageTitleService.setTitle('Spartacus XR');
                $scope.currentProject = null;
                $scope.setMapTitle(null);
                initEmptyMap();

                $state.go('crossReference.home', {}, {notify: false});
                return;
            }

            if (reset) {
                $scope.currentProject = null;
            }

            CrossReferenceProject.get(projectId).then(function (project) {
                pageTitleService.setTitle(project.name, 'Spartacus XR');
                $scope.currentProject = project;

                if ($state.current === 'crossReference.project') {
                    $state.go('crossReference.project', {projectId: project.id}, {notify: false});
                }
            });
        };

        $scope.mapTitle = null;
        $scope.setMapTitle = function(title) {
            $scope.mapTitle = title;
        };

        $scope.watchingLink = null;
        $scope.setWatchingLink = function(link) {
            $scope.watchingLink = link;
        };

        $scope.countryCache = {};
        angular.forEach(modelCache.getList('Country'), function(country) {
            var iso3 = country.iso3;
            $scope.countryCache[iso3] = {
                country: country,
                color: overviewService.colorMap.gray,
                evaluation: null,
            };
        });

        $scope.territoryInfo = {
            enabled: false
        };

        $scope.hoverTerritory = function(territory) {
            var cacheItem = $scope.countryCache[territory];
            $scope.territoryInfo.country = cacheItem ? cacheItem.country : null;
            $scope.territoryInfo.enabled = true;
            $scope.territoryInfo.evaluation = cacheItem.evaluation;

            $timeout(function() {
                $scope.$digest();
            });
        };

        $scope.hoverReset = function() {
            $scope.territoryInfo.enabled = false;
            $scope.territoryInfo.evaluation = null;

            $timeout(function() {
                $scope.$digest();
            });
        };

        function initEmptyMap() {
            $timeout(function () {
                $timeout(function () {
                    worldMap.init('cross-reference-map', {
                        countryInfo: {
                            handler: function (props) {
                                if (props) {
                                    $scope.hoverTerritory(props.adm0_iso);
                                } else {
                                    $scope.hoverReset();
                                }
                            }
                        }
                    });
                });
            });
        }

        initEmptyMap();

    });
