angular.module('naex')

    .factory('faviconService', function ($rootScope, webpackImageCache) {

        $rootScope.favicon = webpackImageCache.getPath('favicon/favicon.png');

        return {
            setFavicon: function (type) {
                if (!type) {
                    $rootScope.favicon = webpackImageCache.getPath('favicon/favicon.png');
                    return;
                }

                $rootScope.favicon = webpackImageCache.getPath('favicon/favicon-' + type + '.png');
            }
        };
    });
