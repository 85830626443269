angular.module('naex.database')

    .factory('DeviceSearch', function (Base) {

        var DeviceSearch = Base.extend({
            name: 'deviceSearch',
            subModels: {
                employee: {
                    model: 'Employee',
                },
                imageList: {
                    model: 'DeviceSearch',
                },
            },
            customMethods: {
                getWithImage: { method: 'GET' },
                getHistory: { method: 'GET', model: true },
                search: { method: 'POST' }
            }
        });

        return DeviceSearch;
    });