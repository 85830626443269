angular.module('naex.crossReference')

    .controller('CrossReferenceProjectItemListCtrl', function ($scope, $rootScope, $timeout, overviewService, worldMap,
                                                               CrossReferenceItem, UserSettings) {

        $scope.sliderUserSetting = null;
        $scope.$watch('currentSheet.id', function(newValue) {
            if (!newValue) {
                return;
            }

            UserSettings.getSetting({ name: 'cross-reference-sheet-activity-' + $scope.currentSheet.id }, $rootScope.$me).then(function(userSetting) {
                if (!userSetting.id) {
                    userSetting = new UserSettings({
                        name: 'cross-reference-sheet-activity-' + $scope.currentSheet.id,
                        value: 6
                    }, $rootScope.$me);
                    userSetting.$save(['name', 'value'], null, { noLoadingText: true });
                }

                $scope.sliderUserSetting = userSetting;
                $scope.filter.slider = parseInt(userSetting.value);
            });
        });

        $scope.filter = {
            slider: 6
        };

        $scope.sliderOptions = {
            range: {
                min: 1,
                max: 36
            },
            format: {
                to: function (value) {
                    return parseInt(value);
                },
                from: function (value) {
                    return value;
                }
            },
            step: 1,
            tooltips: true,
            direction: 'rtl',
            pips: {
                mode: 'steps',
                filter: function(value) {
                    if ([1, 2, 4, 6, 8, 10].indexOf(value) !== -1) {
                        return 1;
                    }

                    return value % 4 ? 0 : 1;
                },
                density: 6
            }
        };

        $scope.setSlider = _.debounce(function() {
            if (!$scope.sliderUserSetting) {
                return;
            }

            var overview = overviewService.overviewTypeList[$scope.currentSheet.type];
            overviewService.setSliderValue($scope.filter.slider);
            angular.forEach($scope.currentProject.territoryList, function(territory) {
                var color = overviewService.overviewTypeList[$scope.currentSheet.type].calculate(territory);
                territory[overview.valueProperty] = color;

                $scope.setColor(territory, null, true, color);

            });

            $scope.sliderUserSetting.value = $scope.filter.slider;
            $scope.sliderUserSetting.$save(['value'], null, { noLoadingText: true });

            $scope.openWatching();
        }, 100);

        $scope.navigateToCountry = function(iso3) {
            worldMap.navigateToCountry(iso3);
        };

        $scope.getTerritoryColor = function(territory) {
            var colorCode;

            if (![
                'brand-strategy-overview',
                'simple-color'
            ].includes($scope.currentSheet.type)) {
                colorCode = overviewService.colorMap[territory[$scope.overviewType.valueProperty]];
            } else {

                var color = 'gray';
                if (territory.valueCustom && territory.valueCustom[$scope.currentSheet.id]) {
                    color = territory.valueCustom[$scope.currentSheet.id];
                }

                colorCode = overviewService.colorMap[color];
            }

            if ($scope.countryCache[territory.country.iso3]) {
                var cacheItem = $scope.countryCache[territory.country.iso3];
                cacheItem.color = colorCode;
                cacheItem.evaluation = overviewService.getEvaluation($scope.currentSheet, territory.country.iso3, $scope.currentProject.territoryList);
            }

            return colorCode;
        };

    });
