angular.module('naex.project')

    .directive('nxActualStepListGridPersonInCharge', function ($compile, $templateCache) {

        var linkFnc;

        return {
            link: function (scope, element, attrs) {
                scope.model = scope.$eval(attrs.nxActualStepListGridPersonInCharge);
                scope.model.isListEmpty = scope.model.taskManagerPersonList.length === 0;

                var tooltipText = [];
                scope.model.taskManagerPersonList.forEach(function (taskManager) {
                    tooltipText.push('- ' + taskManager.name);
                });
                scope.model.tooltipText = tooltipText.sort().join('</br>');

                if (!linkFnc) {
                    var template = $templateCache.get('project/actualStepList/grid/personInCharge/nxActualStepListGridPersonInCharge.html');
                    linkFnc = $compile(template);
                }

                linkFnc(scope, function(cloneElement){
                    element.append(cloneElement);
                });
            }
        };
    });
