angular.module('naex.crossReference')

    .controller('CrossReferenceIndexCtrl', function ($scope, $modal, $stateParams, $timeout, CrossReferenceProject, CrossReferenceSheet) {

        /**
         * SEARCH
         */

        $scope.isSearching = false;
        $scope.searchResult = [];
        $scope.filters = {
            name: null,
            client: null
        };

        $scope.resetFilters = function() {
            $scope.filters.name = null;
            $scope.filters.client = null;
            $scope.$broadcast('resetAutoSuggest', {
                id: 'filters.client'
            });

            $scope.onChangeFilter();
        };

        $scope.onChangeFilter = _.debounce(function () {
            if (!$scope.filters.name && !$scope.filters.client) {
                $timeout(function() {
                    $scope.isSearching = false;
                    $scope.showHistory = true;
                });

                return;
            }

            $scope.showHistory = false;
            $scope.isSearching = true;

            CrossReferenceProject.search({
                name: $scope.filters.name,
                companyId: $scope.filters.client ? $scope.filters.client.item.id : null
            }).then(function (results) {
                $scope.searchResult = results;
                $scope.isSearching = false;
            });
        }, 300);

        /**
         * HISTORY
         */
        $scope.showHistory = true;
        function getHistory() {
            $scope.historyList = [];
            CrossReferenceProject.getHistory().then(function (historyList) {
                $scope.historyList = historyList;

                if ($stateParams.projectId) {
                    $scope.selectProject($stateParams.projectId);
                }
            });
        }

        getHistory();

        /**
         * ADD & EDIT
         */

        $scope.editProject = function (project) {
            var modal = $modal.open({
                windowClass: 'modal-width-1600 modal-with-tabs',
                controller: ['$scope', 'project', 'sheetList', function ($modalScope, project, sheetList) {
                    $modalScope.project = project;
                    $modalScope.sheetList = sheetList;
                }],
                resolve: {
                    project: function() {
                        if (!project) {
                            return new CrossReferenceProject();
                        }

                        return CrossReferenceProject.get(project.id);
                    },
                    sheetList: function() {
                        if (!project) {
                            return [];
                        }
                        return CrossReferenceSheet.getAll({ projectId: project.id });
                    }
                },
                templateUrl: 'crossReference/projectEditPopup/projectEditPopup.html'
            });

            modal.result.then(function() {
                getHistory();
                $scope.onChangeFilter();
            });
        };
    });
