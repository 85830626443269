angular.module('naex.project')

    .directive('nxProjectFavoriteStatus', function ($compile, $templateCache, projectFavoriteService) {

        var linkFnc;

        return {
            link: function (scope, element, attrs) {
                scope.model = scope.$eval(attrs.nxProjectFavoriteStatus);
                scope.projectFavoriteService = projectFavoriteService;

                if (!linkFnc) {
                    var template = $templateCache.get('project/projectList/grid/favoriteStatus/nxProjectFavoriteStatus.html');
                    linkFnc = $compile(template);
                }

                linkFnc(scope, function(cloneElement){
                    element.append(cloneElement);
                });
            }
        };
    });
