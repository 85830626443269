angular.module('naex.crossReference')

    .controller('CrossReferenceProjectEditPopupCtrl', function ($scope, messageBoxService, overviewService) {

        $scope.overviewService = overviewService;
        $scope.deleteProject = function() {
            messageBoxService.confirmWarning('Delete', 'Do you really want to delete this project?', 'Yes', function() {
                $scope.project.$remove().then(function() {
                    $scope.$close();
                });
            });
        };

        $scope.tabIndex = 1;
        $scope.setTabIndex = function (tabIndex) {
            $scope.tabIndex = tabIndex;
        };

    });
