angular.module('naex.crossReference')

    .controller('CrossReferenceProjectEditOptionsCtrl', function ($scope, autoCompleteFilterService, entityConst,
                                                                  messageBoxService, overviewService, CrossReferenceTerritory) {

        $scope.entityConst = entityConst.CrossReference;
        $scope.filterService = autoCompleteFilterService;
        $scope.currentSheet = null;

        $scope.projectSettings = {
            country: null,
            sheetFilter: null,
            sheetId: null
        };

        $scope.onSelectCountry = function() {
            if (!$scope.projectSettings.country) {
                return;
            }

            if (!_.find($scope.project.territoryList, function(item) {
                return item.country.id === $scope.projectSettings.country.item.id;
            })) {
                var item = new CrossReferenceTerritory();
                item.project = $scope.project;
                item.country = $scope.projectSettings.country.item;

                item.$save().then(function() {
                    $scope.project.territoryList.push(item);
                });
            }

            $scope.$broadcast('resetAutoSuggest', {
                id: 'projectSettings.country'
            });
        };

        $scope.updateTerritory = function(territory, property, value) {
            territory[property] = value;
            territory.$save([property]);
        };

        $scope.deleteTerritory = function(territory) {
            messageBoxService.confirmWarning('Delete', 'Do you really want to delete this jurisdiction?', 'Yes', function() {
                territory.$remove($scope.project.territoryList);
            });
        };

        $scope.filterColumn = function(column) {
            if (!$scope.projectSettings.sheetFilter.id) {
                return true;
            }

            return overviewService.overviewTypeList[$scope.projectSettings.sheetFilter.id].columns.indexOf(column) !== -1;
        };

        $scope.sheetFilterOptions = [];
        angular.forEach($scope.sheetList, function(sheet) {
            $scope.sheetFilterOptions.push({
                id: sheet.type,
                name: sheet.name,
                sheetId: sheet.id
            });
        });

        $scope.sheetFilterOptions = _.sortBy($scope.sheetFilterOptions, 'name');
        $scope.sheetFilterOptions.unshift({
            id: null,
            name: 'All'
        });
        $scope.projectSettings.sheetFilter = $scope.sheetFilterOptions[0];

        $scope.sheetFilterApply = function() {
            $scope.currentSheet = _.find($scope.sheetList, function(sheet) {
                return sheet.id === $scope.projectSettings.sheetFilter.sheetId;
            });
        };

        $scope.getTerritoryValueColor = function(territory) {
            if (!$scope.projectSettings.sheetFilter.id) {
                return 'gray';
            }

            if ([
                'brand-strategy-overview',
                'simple-color'
            ].indexOf($scope.projectSettings.sheetFilter.id) !== -1) {
                if (territory.valueCustom && territory.valueCustom[$scope.currentSheet.id]) {
                    return overviewService.colorMap[territory.valueCustom[$scope.currentSheet.id]];
                }

                return overviewService.colorMap.gray;
            }

            var overview = overviewService.overviewTypeList[$scope.projectSettings.sheetFilter.id],
                value = territory[overview.valueProperty];

            return overviewService.colorMap[value];
        };

        $scope.getTerritoryTooltip = function(territory) {
            if (!$scope.projectSettings.sheetFilter.id) {
                return '';
            }

            if ([
                'brand-strategy-overview',
                'simple-color'
            ].indexOf($scope.projectSettings.sheetFilter.id) !== -1) {
                var color = 'gray';
                if (territory.valueCustom && territory.valueCustom[$scope.currentSheet.id]) {
                    color = territory.valueCustom[$scope.currentSheet.id];
                }

                return $scope.currentSheet.customData.colorText[color];
            }

            var overview = overviewService.overviewTypeList[$scope.projectSettings.sheetFilter.id];
            return territory[overview.textProperty];
        };

        $scope.getTerritoryValue = function(territory) {
            var color = 'gray';
            if (territory.valueCustom && territory.valueCustom[$scope.currentSheet.id]) {
                color = territory.valueCustom[$scope.currentSheet.id];
            }

            return color;
        };

        $scope.setTerritoryValue = function(territory, value) {
            if (!territory.valueCustom) {
                territory.valueCustom = {};
            }

            territory.valueCustom[$scope.currentSheet.id] = value;
            territory.$save(['valueCustom'], null, {
                jsonifyObjects: true
            });
        };

    });