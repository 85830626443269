angular.module('naex.watching')

    .controller('WatchingHitListWatchScopeCtrl', function ($scope, Watching, watchingEditPopup, entityConst, trademarkInUseEditPopup, trademarkService) {

        $scope.openWatchingEditPopup = function() {
            Watching.get({ id: $scope.watching.id }).then(function() {
                watchingEditPopup.open($scope.watching).then(function(){
                    $scope.kendoGrid.refreshPage().then(function(){
                        $scope.kendoGrid.refreshSelectedModel();
                    });
                });
            });
        };

        $scope.hasActualWatchingCountry = function(watchingCountryList){
            var result = false;
            _.forEach(watchingCountryList, function(watchingCountry){
                if ($scope.watchingCountryFilter(watchingCountry)){
                    result = true;
                    return false;
                }
            });
            return result;
        };

        $scope.watchingCountryFilter = function(watchingCountry){
            return watchingCountry.country.id == $scope.watchingHit.trademark.countryOfRegistration.id &&
                watchingCountry.country.id != entityConst.Country.EUTM_ID;
        };

        $scope.watchConditionFilter = function (watchingCondition) {
            return !watchingCondition.isTransliteration && !watchingCondition.useOnlyForSearch && !watchingCondition.useOnlyForEvaluation;
        };

        $scope.showTrademark = function(trademark) {
            trademarkService.openTrademarkShowPopup(trademark.id, $scope.countryNameField);
        };

        $scope.openTrademarkInUsePopup = function (trademark) {
            trademarkInUseEditPopup.open(trademark);
        };

    });
