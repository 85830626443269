angular.module('naex.company')

    .controller('UserAccountSelectPopupCtrl', function ($scope, Employee, impersonationService) {

        $scope.setDefault = function(employee) {
            Employee.setDefaultCompany({} , { employeeId: employee.id }).then(function() {
                Employee.getDuplicationList({}, { email: $scope.$me.employee.defaultEmail.value }).then(function(response) {
                    $scope.$me.subAccountList = response.duplicationList;
                });
            });
        };

        $scope.switchUser = function(employee) {
            impersonationService.impersonateUser(employee);
        };

    });