// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/src/assets/images/logo-bg-corsearch.png");
var ___HTML_LOADER_IMPORT_1___ = require("/src/assets/images/logo-bg.png");
var ___HTML_LOADER_IMPORT_2___ = require("/src/assets/images/hl/Logo-protect.jpg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div class=\"wrap height-full\"> <header> <div class=\"container-fluid\"> <div class=\"row-fluid\"> <div class=\"col-md-12\"> <div class=\"pull-left\"> <span ng-controller=\"PageLogoCtrl\"> <span ng-if=\"useCorsearchLogo\"> <a ng-href=\"{{ $me.sessionData.corsearchLogoUrl }}\" class=\"pull-left\" style=\"margin-left:-10px;height:40px;background-color:#fff;text-align:center;margin-right:-5px;padding-left:5px;padding-right:5px\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"\" class=\"logo\" style=\"height:16px\"/> </a> </span> <span ng-if=\"!useCorsearchLogo\"> <a href ui-sref=\"company.home\" class=\"pull-left\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"\" class=\"logo\" ng-if=\"$env.clientEnvironment == 'naex'\"/> <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"\" class=\"logo\" style=\"height:40px;margin-left:-10px;border-right:10px solid #fff\" ng-if=\"$env.clientEnvironment != 'naex'\"/> </a> </span> </span> <div class=\"right\" nx-header-menu></div> </div> <div class=\"pull-right\"> <div class=\"right\" nx-user-info></div> <div class=\"right\" nx-locale-selector></div> </div> </div> </div> </div> </header> <section class=\"data-section height-full\" style=\"position:relative;z-index:0\"> <div class=\"data-background\"></div> <div class=\"data-content height-full\" ui-view></div> </section> </div> ";
// Exports
module.exports = code;